import React, { useState, useEffect, useContext } from "react";
import { MirrorText, Icon, Loading } from "@common";
import { AppContext } from "../../context/AppContext";
import Axios from "axios";

import { useHistory, useParams, useLocation } from "react-router-dom";

import HomeNonCustomized from "./Home_non_customized";
import HomeCustomized from "./Home_customized";
import HomeCustomizedOrgUpdate from "./Home_customizedOrgUpdate";
import HomeCustomizedProUser from "./Home_customizedProUser";
import { ScrollThing, ScrollThingPlay, ScrollThingCross } from "./HomeSegments/ScrollThing";

const Home = () => {

  const history = useHistory();
  // const { shortName } = useParams();
  const search = useLocation().search;
  const lst = new URLSearchParams(search).get("lst");
  const type = new URLSearchParams(search).get("type");
  const number = new URLSearchParams(search).get("number");
  const shortName = new URLSearchParams(search).get("shortName");
  const tokenRemove = new URLSearchParams(search).get("STR");
  
  const id = new URLSearchParams(search).get("id");
  const { selectedGame, setSelectedGame, authenticated, user } = useContext(AppContext);

  const [conditionalHomePage, setConditionalHomePage] = useState(<HomeNonCustomized />);
  // const [conditionalHomePage, setConditionalHomePage] = useState(<Loading height={`30px`} width={`30px`} />)

  useEffect(() => {
    if (tokenRemove) {
      // localStorage.setItem("sessionToken", lst);
      localStorage.removeItem('sessionToken');
        history.push("/");
      
    }
  }, [tokenRemove]);
  useEffect(() => {
    shortName &&
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
        var singleGame = data.filter((game) => game.shortName.toLowerCase() === shortName.toLowerCase());
        if (singleGame.length) {
          setSelectedGame(singleGame[0]);
          // console.log('--------')
         
          if(type && number){
            window.location.replace(`${type}?number=${number}`);
          }else{
            window.location.replace("/");


          }
          // history.push("/");
        }
      });
  }, [shortName]);
  useEffect(() => {
    // console.log(localStorage.getItem("sidebarOpen"))
    let sidebarOpen = localStorage.getItem("sidebarOpen")
    if(!sidebarOpen){
      localStorage.setItem("sidebarOpen", true);
    }
    const timer = setTimeout(() => {
      // console.log('hi i am settime out')
      localStorage.setItem("sidebarOpen", false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (lst) {
      localStorage.setItem("sessionToken", lst);
      if (type && id ) {
        history.push(`/${type}/${id}`);
      }else if(type && number){
        // console.log('--------------------------------',type)
        // history.push(`/${type}?${number}`);
      } else if(!shortName) {
        history.push("/");
      }
    }
  }, [lst]);
  useEffect(() => {
    if (selectedGame && selectedGame.frontpage) {
      if (authenticated) {
        // console.log("Loggedin user is a Normal (No Pro No Org Update)")
        setConditionalHomePage(<HomeCustomized userType="LoggedIn" segmentData={selectedGame.frontpage.LoggedIn} />); //If Loggedin user is Normal (No Pro No Org Update)
      } else {
        // console.log("User Not Logged In)")
        setConditionalHomePage(<HomeCustomized userType="NonLoggedIn" segmentData={selectedGame.frontpage.Default} />); // If user is not loggedin
      }
    }
  }, [selectedGame, authenticated]);
  return (
    <div>
      {/* <ScrollThing />
      <ScrollThingPlay />
      <ScrollThingCross /> */}
      <MirrorText text={["l34gu3s.gg", "Esp0rt"]} />
      {conditionalHomePage}
    </div>
  );
};

export default Home;
