
import React, { useState ,useEffect} from "react";

import Axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../../../_common/Loading/Loading";
import { useHistory } from "react-router-dom";

const ConnectLOL = ({ gameData, afterConnect,selectedGame }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [summonerName, setSummonerName] = useState("");
  const [primaryRole, setPrimaryRole] = useState("");
  const [secondaryRole, setSecondaryRole] = useState("");
  const [code, setCode] = useState("");
  const [session, setSession] = useState("");
  // let session= localStorage.getItem('sessionToken')
  useEffect(() => {
    let sessionStorage= localStorage.getItem('sessionToken')
    setSession(sessionStorage)
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_LOL_API}/api/connect`, {
        game: gameData._id,
        name: summonerName,
        primaryRole: primaryRole,
        secondaryRole: secondaryRole,
        code,
      });
      toast.success("Successfully created LoL player!");
      setLoading(false);

      afterConnect(data.player);

      history.push(`/game-profile/${data.player._id}`);
    } catch (e) {
      const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem creating player";
      toast.error(msg);
      setLoading(false);
    }
  };

  const roles = gameData.configuration.playerTypes.filter(({ name }) => !name.includes("SUB")) || [];

  return (
    <>
   { selectedGame?.shortName==="LoL" &&<form onSubmit={onSubmit} className="form">

      <div className="form-row">
        <label htmlFor="summoner-name">Summoner Name</label>
        <input
          onBlur={(e) => {
            e.target.value = e.target.value.trim();
          }}
          type="text"
          id="summoner-name"
          placeholder="Summoner name"
          className="form-input"
          value={summonerName}
          onChange={(e) => setSummonerName(e.target.value)}
        />
      </div>

      <div className="form-row">
        <label class="pointer-true" htmlFor="verification-code">
          <span>Verification Code</span>
          <a href="/news/connecting-summoner-id" target="_blank">
            What's that?
          </a>
        </label>
        <input
          onBlur={(e) => {
            e.target.value = e.target.value.trim();
          }}
          type="text"
          id="verification-code"
          className="form-input"
          placeholder="Verification code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
      </div>

      <div className="form-row">
        <label htmlFor="primaryRole">Preferred Primary Role</label>
        <select id="primaryRole" className="form-input" value={primaryRole || "_DEFAULT"} onChange={(e) => setPrimaryRole(e.target.value)}>
          <option value="_DEFAULT" disabled>
            Choose preferred primary role
          </option>
          {roles.map(({ name }) => {
            return name !== secondaryRole ? (
              <option key={name} value={name}>
                {name}
              </option>
            ) : null;
          })}
        </select>
      </div>

      <div className="form-row">
        <label htmlFor="secondaryRole">Preferred Secondary Role</label>
        <select
          id="secondaryRole"
          className="form-input"
          value={secondaryRole || "_DEFAULT"}
          onChange={(e) => setSecondaryRole(e.target.value)}
        >
          <option value="_DEFAULT" disabled>
            Choose preferred secondary role
          </option>
          {roles.map(({ name }) => {
            return name !== primaryRole ? (
              <option key={name} value={name}>
                {name}
              </option>
            ) : null;
          })}
        </select>
      </div>

      <button type="submit" className="button primary thicc" disabled={true}>
      {/* <button type="submit" className="button primary thicc" disabled={!summonerName || !code || !primaryRole || !secondaryRole || loading}> */}
        {loading ? (
          <>
            <Loading width={30} height={30} /> Connecting
          </>
        ) : (
          "Connect"
        )}
      </button>
    </form>}
    { selectedGame?.shortName!=="LoL" && 
    <>
      <div className="create-text">  <span> Right now you are not on this Game </span></div>
    <a 
      // href={`${process.env.REACT_APP_CSGO_API}/authenticate`}
      href={`${process.env.REACT_APP_LOL_URL}?lst=${session}&shortName=LOL&type=account&number=3`}
      >
          <button className="button primary thicc lol-button">Get Me There</button>
       
      </a>
      </>
      }
    </>
  );
  }



export default ConnectLOL;
