import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";
import ConnectLOL from "./LOL/ConnectLOL";
import ConnectCSGO from "./CSGO/ConnectCSGO";
import NonIntegrationGame from "./non-integration/nonIntegration";
import { AppContext } from "@context/AppContext";
import useTranslated from "../../../../helpers/translationHelper";
import ConnectVL from "./VL/ConnectVL"; 

const ConnectGame = ({ onClose, afterCreate }) => {
  const { user, selectedGame } = useContext(AppContext);
  const selectedGameName = localStorage.getItem("selectedGame")
  const [games, setGames] = useState([]);
  const [game, setGame] = useState("");

  const addgamelable=useTranslated("id_pop-up_-_create_player_2")

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/gamesLimit/${user.state._id}`).then(({ data }) => {
      // setGames(data.filter(({ _id }) => (user.state.players ? !user.state.players.map(({ game }) => game._id).includes(_id) : true)));
      setGames(() => {
        return data.playerGames.filter((game) => game.name !== "No Game");
      });
    });
  }, [user.state]);

  const afterConnect = (player) => {
    afterCreate(player);
    onClose();
  };

  const getGameData = () => {
    if (game) {
      return games.find(({ shortName }) => game === shortName);
    }

    return false;
  };
  return (
    <div>
      <div className="form-row">
        <label htmlFor="game">{addgamelable/* Add game account */}</label>
        <select id="game" className={`form-input ${game === "CSGO"?"csgo-sel":game === "VL"?"vl-sel":game === "LoL"?"lol-sel":''}`}  value={game || "_DEFAULT"} onChange={(e) => setGame(e.target.value)}>
          <option value="_DEFAULT" disabled>
            Choose game
          </option>
          {games.map(({ _id, name, shortName, playerLimitFull }) => (
            <option key={_id} value={shortName} disabled={playerLimitFull}>
              {name} {playerLimitFull && "(Account Limit Full)"}
            </option>
          ))}
        </select>
      </div>

      {/* In the near future this will contain route to different components based on selected game, for extra functionality such as verifying summoner name */}

      {games.map(({ _id, name, shortName }) => {
        if (game === shortName) {
          switch (shortName) {   
            case "CSGO":
              return <ConnectCSGO key={_id} gameData={getGameData()} afterConnect={afterConnect}  selectedGame={selectedGame}/>;
              break;
            case "LoL":
              return <ConnectLOL key={_id} gameData={getGameData()} afterConnect={afterConnect} selectedGame={selectedGame} />;
              break;
              case "VL":
                return <ConnectVL key={_id} gameData={getGameData()} afterConnect={afterConnect} selectedGame={selectedGame} />;
                break;
            default:
              return <NonIntegrationGame afterConnect={afterConnect} game={_id} user={user.state._id} />;
          }
        }
        // if (game === name)
        //   return <ConnectCSGO key={_id} gameData={getGameData()} afterConnect={afterConnect} />;
        // return false;
      })}

    </div>
  );
};

export default ConnectGame;
